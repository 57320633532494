  .upload-card{
    width: 30%;
    height: 100%;
    padding: 15px;
    // margin-top: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    // box-shadow: 15px 10px 25px 0px  #3fa1a9;
    background: #fff;
    animation: open .5s;
    // margin: 3% 0;
    position: relative;
    z-index: 2;

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      .field{
        margin: 5px;
        display: flex;
        flex-direction: column;
        }
      input[type="file"] {
        display: none;
      }
      
      .custom-file-upload {
        // border-radius: 50%;
        border-radius: 15px;
        display: inline-block;
        position: relative;
        padding: 6px;
        cursor: pointer;
        background: linear-gradient(270deg, #bebebe, #b7b7b7);
        margin-bottom: 25px;
        width: 100%;
      }
      
      .img-wrap{
        position: relative;
        // width: 200px;
        height: 300px;
        overflow: hidden;
        border-radius: 15px;
      }
      .img-upload:before{
        content: url(browse.png);
        font-size: 90px;
        position: absolute;
        padding-top: 80px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #272727;
        width: 100%;
        height: 300px;
        // border-radius: 50%;
        opacity: 0;
        transition: .5s ease;
        background-color: #fff;
      }
      .img-upload:hover:before{
       opacity: 1;
      }
      img {
        width: auto;
        height: 100%;
      }
      
      label{
        text-transform: uppercase;
        font-weight: 700;
        color: #676767;
      }
      
      input{
        border-radius: 15px;
        border: 1px solid #b7b7b7;
        padding: 5px 5px 5px 10px;
        font-size: 18px;
        transition: 0.2s;
      }
      input:focus{
        outline: none;
        border: 1px solid #272727;
      }
      input::placeholder{
        color: #bebebe;
      }
      
      button{
        position: relative;
        color: #181818;
        letter-spacing: 1px;
        font-size: 18px;
        padding: 10px;
        text-align: center;
        transition: 0.5s;
        border-radius: 10px;
        cursor: pointer;
        border-radius: 25px;
        border: none;
        background: #fff;
        width: 180px;
      }
      .save{
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        width: 180px;
        // box-shadow: 20px 0px 40px 0px  #454545;
      }
      .edit{
        color: #181818;
        width: 180px;
      }
      button:hover{
        left: 0;
        color: #fff;
        width: 180px;
        box-shadow: 0px 0px 20px 0px  #889999;
        background: #272727;
      }
      button:focus{
        outline: none;
      }
      .lds-ripple {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
      }
      .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }
  }
  @keyframes open {
    0%  {background-position: 166% -25%, 220% -69%;}
    100%{background-position: 120% -5%, 200% -40%;}
  }
  
  