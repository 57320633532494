.App {
  text-align: center;
  padding: 1%;

  .Cards {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    transition: ease 1s;
    margin-top: 3%;

    .carousel {
      width: 40%;
      z-index: 2;
      position: relative;

      .slide, .selected {
        background-color: transparent !important;
      }
    }
  }
}

.App-header {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(50px + 2vmin);
  color: white;
}

h4 {
  color: white;
  margin-bottom: -1rem;
}
