.FoodDetails {
    width: 100%;
    height: 28.5rem;
    padding: 15px;
    // margin-top: 40px;
    border-radius: 25px;
    // box-shadow: 15px 10px 25px 0px  #3fa1a9;
    background: #fff;
    animation: open .5s;
    // margin: 3% auto;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    .nutrition {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
    }
}